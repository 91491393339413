import React, { useEffect, useState } from 'react';
import { Card, Col, Modal, Pagination, Popconfirm, Row, Space, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { MdOutlineUnpublished } from 'react-icons/md';
import { Filter } from 'iconsax-react';
import { RiArrowLeftLine, RiCloseFill, RiErrorWarningLine } from 'react-icons/ri';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import urlPageBonuses from '../../../../../urls/urlPageBonuses';
import urlPageBonusDetails from '../../../../../urls/urlPageBonusDetails';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../../const/system';
import { imageStatuses } from '../../../../../const/imageStatuses';
import { Permissions } from '../../../../../const/permissions';
import appColors from '../../../../../const/colors';
import { PrivateComponent } from '../../../../../components/HasRights/HasRights';
import useQueryParams from '../../../../../app/hooks/useQueryParams';
import useToggle from '../../../../../app/hooks/useToggle';
import useScrollToImagesOnSearch from '../../../hooks/useScrollToImagesOnSearch';
import applyFilter from '../../../../../lib/applyFilter';
import resetFilter from '../../../../../lib/resetFilter';
import handleResponse from '../../../../../lib/handleResponse';
import Spinner from '../../../../../components/Spinner';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import ActionButton from '../../../../../layout/components/action-button';
import FormCreateEditBonus from '../../../forms/FormCreateEditBonus';
import Sidebar from '../../../../../components/Sidebar';
import FormImagesFilter from '../../../forms/FormImagesFilter';
import ContentCard from '../../../components/ContentCard';
import TotalLabel from '../../../../../components/TotalLabel';
import PreviewImageCard from '../../../components/PreviewImageCard';
import EmptyDataPlaceholder from '../../../../../components/EmptyDataPlaceholder';
import { useGetSpecificBonusQuery, useUnpublishBonusMutation, useUpdateBonusMutation } from '../../../api/bonusesApiSlice';
import { useAttachImageMutation, useGetImagesQuery } from '../../../api/imagesApiSlice';



const PageBonusEdit = () => {
  const intl = useIntl();
  const { bonusId } = useParams();
  const navigate = useNavigate();
  const [ isFilterOpen, toggleFilterSidebar ] = useToggle();
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const [ initialValues, setInitialValues ] = useState({});
  const [ initialErrors, setInitialErrors ] = useState({});
  const [ initFilterValues, setInitFilterValues ] = useState({});
  const [ previewModalOpen, togglePreviewModal ] = useToggle();
  const [ previewImage, setPreviewImage ] = useState({});

  const searchFilterFields = [ 'id', 'folder', 'category', 'main_category', 'image_type', 'tags', 'taps_from', 'taps_to', 'colors_from', 'colors_to', 'imported_from', 'imported_to', 'released_from', 'released_to' ];

  const {
    pagination,
    searchParams,
    handleChangeTableParams,
  } = useQueryParams({
    searchFilterFields,
    isFilter: true,
    paginationInit: { page: 1, limit: 12 } });

  const { data: bonusDetails = {}, isFetching: isLoadingBonusDetails } = useGetSpecificBonusQuery(bonusId, { skip: !bonusId });
  const [ updateBonus, { isLoading: isUpdatingBonus } ] = useUpdateBonusMutation();
  const [ attachImage, { isLoading: isAttaching } ] = useAttachImageMutation();
  const [ unpublishBonus, { isLoading: isUnpublishBonusLoading } ] = useUnpublishBonusMutation();

  const { data: {
    data: images = [],
    pagination: dataPagination = {},
  } = { data: [], pagination: {} }, isFetching } = useGetImagesQuery({
    queryParams: searchParams.toString().includes('search') ? `${searchParams.toString()};status:ready_for_release` : `${searchParams.toString()}&search=status:ready_for_release`,
  });

  const imagesRef = useScrollToImagesOnSearch(images, searchQueryParams);
  const attachedImagesCount = bonusDetails?.images?.data?.length;

  const allowUnpublishPast = bonusDetails?.publish?.allow_unpublish_past ?? true;
  const allowUnpublish = allowUnpublishPast ?
    Boolean(bonusDetails?.is_published) :
    Boolean(bonusDetails?.is_published) && dayjs().isSameOrBefore(dayjs(bonusDetails?.released_at), 'day');

  useEffect(() => {
    if (!isEmpty(bonusDetails)) {
      setInitialValues({
        title: bonusDetails.title,
        code: bonusDetails.code,
        released_at: bonusDetails.released_at,
        released_to: bonusDetails.released_to,
        description: bonusDetails.description,
        is_published: false,
        updated_at: bonusDetails.updated_at,
      });
    }
  }, [ bonusDetails ]);

  const handleSubmit = (values) => {
    updateBonus({ bonusId, values })
      .unwrap()
      .then(() => handleResponse(
        'success',
        intl,
        'ui-general-updated-successfully',
        [
          () => setInitialValues(values),
          () => {
            if (values.is_published) {
              navigate(urlPageBonusDetails({ bonusId }));
            }
          },
        ],
      ))
      .catch((error) => handleResponse(
        'error',
        intl,
        'ui-general-update-failed',
        [
          () => setInitialErrors(error?.data?.errors),
        ],
        error,
      ));
  };

  const handleUnpublish = () => {
    unpublishBonus(bonusId)
      .unwrap()
      .then(() => handleResponse('success', intl, 'ui-general-unpublished-successfully'))
      .catch((error) => handleResponse('error', intl, 'ui-general-unpublish-failed', [], error));
  };

  const handleAttach = (imageId, status) => {
    const data = {
      attached_to: bonusId,
      type: imageStatuses.bonus,
      status,
    };

    attachImage({ id: imageId, payload: data })
      .unwrap()
      .then(() => handleResponse('success', intl, 'bonus-attach-success'))
      .catch((error) => handleResponse('error', intl, 'bonus-attach-failed', [], error));
  };

  const actions = (item, isDetach = false) => {
    return [ (
      <Tooltip
        key="download"
        placement="bottom"
        title={<IntlMessages id={isDetach ? 'ui-general-detach-image' : 'ui-general-attach-image'} />}
      >
        {isDetach ? (
          <Popconfirm
            title={<IntlMessages id="bonus-detach-confirm-message" />}
            placement="top"
            onConfirm={() => {
              handleAttach(item.id, imageStatuses.ready_for_release);
            }}
            okText={<IntlMessages id="ui-general-yes" />}
            cancelText={<IntlMessages id="ui-general-no" />}
            icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
            okButtonProps={{ danger: true }}
          >
            <DeleteOutlined style={{ color: appColors.orange }} />
          </Popconfirm>
        ) : (
          <Popconfirm
            title={<IntlMessages id="bonus-attach-confirm-message" />}
            placement="top"
            onConfirm={() => {
              handleAttach(item.id, imageStatuses.bonus);
            }}
            okText={<IntlMessages id="ui-general-yes" />}
            cancelText={<IntlMessages id="ui-general-no" />}
            icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
          >
            <PlusOutlined style={{ color: appColors.mediumGreen }} />
          </Popconfirm>
        )}
      </Tooltip>
    ) ];
  };

  const handleCardClick = (item) => {
    setPreviewImage(item);
    togglePreviewModal();
  };

  const handlePreviewModalClose = () => {
    setPreviewImage({});
    togglePreviewModal();
  };

  const showTotal = (total) => <TotalLabel total={total} />;

  const isLoading = isLoadingBonusDetails || isUpdatingBonus || isFetching || isAttaching || isUnpublishBonusLoading;

  return (
    <>
      <Modal
        width={1200}
        title={intl.formatMessage({ id: 'content-image-preview-card-title' })}
        centered
        visible={previewModalOpen}
        onCancel={handlePreviewModalClose}
        footer={false}
        closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
      >
        <PreviewImageCard
          data={previewImage}
          canUpdate={false}
          onModalClose={handlePreviewModalClose}
        />
      </Modal>

      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPageBonuses()}>
              <IntlMessages id='bonuses-breadcrumbs' />
            </Link>
          }
          breadCrumbParent2={
            <Link to={urlPageBonusDetails({ bonusId })}>
              {bonusDetails?.id ?? EMPTY_VALUE_PLACEHOLDER}
            </Link>
          }
          breadCrumbActive={<IntlMessages id='ui-general-edit' />}
        />

        <Col md={12} span={24}>
          <Row
            gutter={[ 8, 8 ]}
            justify="end"
            align="middle"
          >
            <PrivateComponent allowedPermissions={[ Permissions.CONTENT.BONUSES.UNPUBLISH ]}>
              {allowUnpublish && (
                <Col>
                  <Space>
                    <ActionButton
                      loading={isUnpublishBonusLoading}
                      title={intl.formatMessage({ id: 'ui-general-unpublish' })}
                      icon={<MdOutlineUnpublished className="hp-mr-8" size={18} />}
                      onClick={handleUnpublish}
                    />
                  </Space>
                </Col>
              )}
            </PrivateComponent>
            <Col>
              <Space>
                <ActionButton
                  title={intl.formatMessage({ id: 'ui-general-back-to-list' })}
                  icon={<RiArrowLeftLine className="hp-mr-8" size={18} />}
                  onClick={() => {
                    navigate(urlPageBonuses());
                  }}
                />
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>

      <Spinner spinning={isLoading}>
        <Row gutter={[ 32, 32 ]} className="hp-mb-32">
          <Col span={24}>
            <Card className="hp-border-color-black-40 hp-card-6">
              <FormCreateEditBonus
                isEdit
                initialValues={initialValues}
                onSubmit={handleSubmit}
                onCancel={() => {}}
                hasReleasedImages={attachedImagesCount > 0}
                isSubmitting={isUpdatingBonus || isUnpublishBonusLoading}
                initialErrors={initialErrors}
              />
            </Card>
          </Col>
        </Row>

        <Row gutter={[ 32, 32 ]} ref={imagesRef}>
          <Sidebar
            visible={isFilterOpen}
            toggleSidebar={toggleFilterSidebar}
            width={600}
          >
            <FormImagesFilter
              isSubmitting={isFetching}
              initialValues={initFilterValues}
              excludedFields={[ 'status', 'released_from', 'released_to' ]}
              onCancel={() => {
                resetFilter({
                  searchQueryParams,
                  setSearchParams,
                  setInitFilterValues,
                });
              }}
              onSubmit={(values) => {
                applyFilter({
                  values,
                  searchQueryParams,
                  searchFilterFields,
                  setSearchParams,
                  toggleFilterSidebar,
                });
              }}
            />
          </Sidebar>

          <Col xs={24} lg={12}>
            <Card
              width="100%"
              title={(
                <div className="hp-d-flex hp-d-flex-between hp-align-items-center">
                  <IntlMessages id="ui-general-ready-to-release" />
                  <ActionButton
                    title=""
                    className='hp-mr-sm-8'
                    icon={<Filter size={18} />}
                    onClick={toggleFilterSidebar}
                  />
                </div>
              )}
            >
              {images?.length ? (
                <Row gutter={[ 16, 32 ]}>
                  {images?.map((item) => {
                    return (
                      <Col key={item.id} className='gutter-row' xs={24} sm={12} xxl={8}>
                        <ContentCard
                          allowImageClick
                          data={item}
                          onClick={handleCardClick}
                          actions={actions(item)}
                        />
                      </Col>
                    );
                  })}
                  <Col span={24}>
                    {dataPagination.total && (
                      <Pagination
                        total={dataPagination.total}
                        showTotal={showTotal}
                        pageSize={pagination.limit}
                        current={pagination.page}
                        pageSizeOptions={[ '12', '24', '48', '96' ]}
                        onChange={(page, size) => {
                          handleChangeTableParams({
                            current: page,
                            pageSize: size,
                          }, {}, {});
                        }}
                      />
                    )}
                  </Col>
                </Row>
              ) : (
                <EmptyDataPlaceholder placeholder={<IntlMessages id="ui-general-no-images-found" />} />
              )}
            </Card>
          </Col>
          <Col xs={24} lg={12}>
            <Card
              width="100%"
              title={
                <div className="hp-d-flex hp-d-flex-between hp-align-items-center">
                  <IntlMessages id="bonuses-breadcrumbs" />
                  <TotalLabel total={attachedImagesCount} />
                </div>
              }
            >
              <Row gutter={[ 16, 32 ]}>
                {attachedImagesCount ?
                  bonusDetails?.images?.data?.map((item) => {
                    return (
                      <Col key={item.id} className='gutter-row' xs={24} sm={12} xxl={8}>
                        <ContentCard
                          allowImageClick
                          data={item}
                          actions={actions(item, true)}
                          onClick={handleCardClick}
                        />
                      </Col>
                    );
                  }) : (
                    <EmptyDataPlaceholder placeholder={<IntlMessages id="bonus-no-attached-images" />} />
                  )}
              </Row>
            </Card>
          </Col>
        </Row>
      </Spinner>
    </>
  );
};

export default PageBonusEdit;
