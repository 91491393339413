const sidebar = {
  'sidebar-pages': 'Pages',
  'sidebar-other': 'Other',
  'sidebar-rbac': 'RBAC',
  'sidebar-pages-rbac-users': 'Users',
  'sidebar-featured': 'Featured',
  'sidebar-featured-news': 'News',
  'sidebar-featured-news-list': 'List',
  'sidebar-featured-news-priority': 'Priority',
  'sidebar-featured-old-news': 'Old news',
  'sidebar-featured-old-news-list': 'List',
  'sidebar-featured-old-news-priority': 'Priority',
  'sidebar-featured-news-icons': 'Icons',
  'sidebar-featured-news-dictionary': 'Dictionary',
  'sidebar-featured-news-dictionary-btn': 'Dictionary button',
  'sidebar-featured-daily': 'Daily',
  'sidebar-featured-editors-choice': 'Editor\'s choice',
  'sidebar-featured-external-promo': 'External promo',
  'sidebar-featured-priority': 'Priority',
  'sidebar-pages-push-icons': 'Push Icons',
  'sidebar-pages-deeplinks': 'Deeplinks',
  'sidebar-pages-popups': 'Popups',
  'sidebar-pages-blank-page': 'Blank Page',
  'sidebar-pages-error': 'Error Pages',
  'sidebar-view-profile': 'View Profile',
  'sidebar-translations': 'Translations',
  'sidebar-content': 'Content',
  'sidebar-content-preview': 'Images',
  'sidebar-content-categories': 'Categories',
  'sidebar-content-categories-priority': 'Priority',
  'sidebar-content-achievements': 'Achievements',
  'sidebar-content-bonuses': 'Bonuses',
  'sidebar-template-constructors': 'Template builder',
  'sidebar-templates': 'Templates',
  'sidebar-presets': 'Presets',
  'sidebar-general-list': 'List',
  'sidebar-general-priority': 'Priority',
  'sidebar-general-logs': 'Logs',
};

export default sidebar;
