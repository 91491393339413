import React, { useState } from 'react';
import { Card, Col, Row, Space, Dropdown, Button, Menu, Modal, Popconfirm } from 'antd';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { RiArrowLeftLine, RiCloseFill } from 'react-icons/ri';
import { DownOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { PiUpload, PiPencilSimpleLine, PiTrashSimple, PiGear } from 'react-icons/pi';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import urlPageFeatures from '../../../../urls/urlPageFeatures';
import urlPageFeaturesEdit from '../../../../urls/urlPageFeaturesEdit';
import urlPageFeaturePriorities from '../../../../urls/urlPageFeaturePriorities';
import Spinner from '../../../../components/Spinner';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import ActionButton from '../../../../layout/components/action-button';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { Permissions } from '../../../../const/permissions';
import {
  DATE_FORMAT,
  EMPTY_IMAGE_PLACEHOLDER,
  EMPTY_VALUE_PLACEHOLDER,
} from '../../../../const/system';
import useToggle from '../../../../app/hooks/useToggle';
import handleResponse from '../../../../lib/handleResponse';
import getFeatureSourceTranslation from '../../utils/getFeatureSourceTranslation';
import getFeatureSourceFromUrl from '../../utils/getFeatureSourceFromUrl';
import getFeatureSourceKey from '../../utils/getFeatureSourceKey';
import getFeatureConfiguration from '../../utils/getFeatureConfiguration';
import mkPreparedTemplateData from '../../../Content/utils/mkPreparedTemplateData';
import FeaturedDetailsCard from '../../components/FeaturedDetailsCard';
import TemplateCollapsibleCards from '../../../../components/TemplateCollapsibleCards';
import PreviewImageCard from '../../../Content/components/PreviewImageCard';
import { PrivateComponent } from '../../../../components/HasRights/HasRights';
import { getDictionaryIdFromDynamicData } from '../PageFeatures/PageFeatures.const';
import { useGetLanguagesQuery } from '../../../Translations/api/languagesApiSlice';
import { useGetDictionaryQuery } from '../../../Translations/api/dictionariesApiSlice';
import {
  useDeleteFeatureMutation,
  useGetSpecificFeatureQuery,
  useUnpublishFeatureMutation,
} from '../../api/featuresApiSlice';



dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const PageFeatureDetails = () => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const { featureId } = useParams();
  const navigate = useNavigate();
  const [ previewImage, setPreviewImage ] = useState({});
  const [ previewModalOpen, togglePreviewModal ] = useToggle();

  const featureSource = getFeatureSourceFromUrl(pathname);
  const featureConfig = getFeatureConfiguration(featureSource);
  const featureSourceKey = getFeatureSourceKey(featureSource);

  const [ unpublish, { isLoading: isUnpublishFeatureLoading } ] = useUnpublishFeatureMutation();

  const { data: featureDetails = {}, isLoading } = useGetSpecificFeatureQuery({
    featureSource,
    id: featureId,
    include: 'images',
  });

  const dictionaryId = getDictionaryIdFromDynamicData(featureDetails?.dynamic_data?.data);

  const contentEnabled = featureConfig?.config?.content?.enabled ?? true;
  const allowUnpublishPast = featureConfig?.config?.publish?.allow_unpublish_past ?? true;
  const allowUpdatePast = featureConfig?.config?.publish?.allow_update_past ?? true;
  const releaseDate = featureDetails?.released_at;
  const allowUnpublish = allowUnpublishPast ?
    featureDetails?.is_published :
    featureDetails?.is_published && dayjs().isSameOrBefore(dayjs(releaseDate), 'day');
  const allowUpdate = allowUpdatePast ? true : dayjs().isSameOrBefore(dayjs(releaseDate).subtract(-1, 'days'), 'day');

  const { data: languages = { languages: [] } } = useGetLanguagesQuery({ queryParams: 'limit=0' });

  const { data: dictionary = {} } = useGetDictionaryQuery({
    id: dictionaryId,
    include: 'structure',
  }, { skip: !dictionaryId });

  const [ deleteFeature, { isLoading: isDeletingFeature } ] = useDeleteFeatureMutation();

  const data = mkPreparedTemplateData({
    templateData: featureDetails?.template?.data?.fields?.data,
    dynamicData: featureDetails?.dynamic_data?.data,
    dictionary,
    languages: languages?.languages,
  });

  const handleUnpublish = () => {
    unpublish({ featureSource, featureId })
      .unwrap()
      .then(() => handleResponse('success', intl, 'ui-general-unpublished-successfully'))
      .catch((error) => handleResponse('error', intl, 'ui-general-unpublish-failed', [], error));
  };

  const handleDelete = () => {
    deleteFeature({ featureSource, id: featureId })
      .unwrap()
      .then(() => handleResponse(
        'success',
        intl,
        'ui-general-deleted-successfully',
        [
          () => navigate(urlPageFeatures({ featureSource })),
        ],
      ))
      .catch((error) => handleResponse('error', intl, 'ui-general-delete-failed', [], error));
  };


  const handleImageClick = (item) => {
    setPreviewImage(item);
    togglePreviewModal();
  };

  const handlePreviewModalClose = () => {
    setPreviewImage({});
    togglePreviewModal();
  };

  const actionItems = (
    <Menu>
      <PrivateComponent allowedPermissions={[ Permissions.FEATURED.FEATURES.PRIORITY.LIST ]}>
        {featureDetails.released_at && (
          <Menu.Item
            key="priority"
            icon={<PiGear size={18} />}
            onClick={() => {
              const preparedData = dayjs(featureDetails.released_at).format(DATE_FORMAT);

              return navigate({
                pathname: urlPageFeaturePriorities(),
                search: `?search=date%3A${preparedData}`,
              });
            }}
          >
            <IntlMessages id="ui-general-priority" />
          </Menu.Item>
        )}
      </PrivateComponent>
      <PrivateComponent allowedPermissions={[ Permissions.FEATURED.FEATURES[featureSourceKey].EDIT ]}>
        {allowUpdate && (
          <Menu.Item
            key="edit"
            icon={<PiPencilSimpleLine size={18} />}
            onClick={() => navigate(urlPageFeaturesEdit({ featureSource, featureId }))}
          >
            <IntlMessages id="ui-general-edit" />
          </Menu.Item>
        )}
        {allowUnpublish && (
          <Menu.Item
            key="unpublish"
            onClick={handleUnpublish}
            icon={<PiUpload size={18} />}
          >
            <IntlMessages id="features-details-unpublish" />
          </Menu.Item>
        )}
      </PrivateComponent>
      <PrivateComponent allowedPermissions={[ Permissions.FEATURED.FEATURES[featureSourceKey].DELETE ]}>
        {!featureDetails.is_published &&
        <Popconfirm
          title={<IntlMessages id="ui-general-delete-confirm-message" />}
          placement="top"
          onConfirm={() => {
            handleDelete(featureDetails.id);
          }}
        >
          <Menu.Item
            key="delete-feature"
            onClick={() => {}}
            icon={<PiTrashSimple size={18} />}
          >
            <IntlMessages id="ui-general-delete" />
          </Menu.Item>
        </Popconfirm>}
      </PrivateComponent>
    </Menu>
  );

  return (
    <>
      <Modal
        width={1200}
        title={intl.formatMessage({ id: 'content-image-preview-card-title' })}
        centered
        visible={previewModalOpen}
        onCancel={handlePreviewModalClose}
        footer={false}
        closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
      >
        <PreviewImageCard
          data={previewImage}
          canUpdate={false}
          onModalClose={handlePreviewModalClose}
        />
      </Modal>

      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <Col md={14} span={24}>
          <BreadCrumbs
            breadCrumbParent={
              <Link to={urlPageFeatures({ featureSource })}>
                <IntlMessages id={getFeatureSourceTranslation(featureSource)} />
              </Link>
            }
            breadCrumbActive={featureDetails?.title ?? EMPTY_VALUE_PLACEHOLDER}
          />
        </Col>

        <Col md={10} span={24} className='text-end'>
          <Space size='middle'>
            <PrivateComponent
              allowedPermissions={[
                Permissions.FEATURED.FEATURES.PRIORITY.LIST,
                Permissions.FEATURED.FEATURES[featureSourceKey].EDIT,
                Permissions.FEATURED.FEATURES[featureSourceKey].DELETE,
              ]}
            >
              <Dropdown overlay={actionItems}>
                <Button onClick={(ev) => ev.preventDefault()}>
                  <Space size='middle'>
                    <IntlMessages id='ui-general-action' />
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </PrivateComponent>

            <ActionButton
              title={<IntlMessages id='ui-general-back-to-list' />}
              icon={<RiArrowLeftLine className="hp-mr-8" size={18} />}
              onClick={() => {
                navigate(urlPageFeatures({ featureSource }));
              }}
            />
          </Space>
        </Col>
      </Row>

      <Spinner spinning={isLoading || isDeletingFeature || isUnpublishFeatureLoading}>
        <Row gutter={[ 32, 32 ]}>
          <Col span={24}>
            <Card className="hp-border-color-black-40 hp-card-6">
              <Row gutter={[ 32, 32 ]}>
                {contentEnabled && (
                  <Col xs={24} md={8} className='centered-item'>
                    {featureDetails?.images?.data.length ? featureDetails?.images?.data.map((image) => (
                      <LazyLoadImage
                        key={image.id}
                        style={{ width: '20vh', cursor: 'pointer' }}
                        className='hp-m-10 hp-cursor-pointer'
                        onClick={() => handleImageClick(image)}
                        effect='blur'
                        alt="feature details img preview"
                        src={image?.url ?? EMPTY_IMAGE_PLACEHOLDER}
                      />
                    )) : (
                      <LazyLoadImage
                        effect='blur'
                        alt="feature details img preview"
                        src={EMPTY_IMAGE_PLACEHOLDER}
                      />
                    )}
                  </Col>
                )}
                <Col xs={24} md={contentEnabled ? 16 : 24}>
                  <FeaturedDetailsCard
                    data={featureDetails}
                    featureConfig={featureConfig}
                  />

                  <TemplateCollapsibleCards
                    dictionary={dictionary}
                    languages={languages?.languages}
                    data={data}
                    targetData={featureDetails?.target?.data}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Spinner>
    </>
  );
};

export default PageFeatureDetails;
