import { Tag } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from '../../layout/components/lang/IntlMessages';



const StatusTag = ({ active }) => {
  return (
    <Tag color={active ? 'success' : 'error'}>
      <IntlMessages id={active ? 'ui-general-yes' : 'ui-general-no'} />
    </Tag>
  );
};

StatusTag.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default StatusTag;
