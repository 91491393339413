import React from 'react';
import { Popconfirm, Row, Tag, Tooltip } from 'antd';
import { PiPencil } from 'react-icons/pi';
import { IoEarth } from 'react-icons/io5';
import { Delete } from 'react-iconly';
import { Eye } from 'iconsax-react';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { RiErrorWarningLine } from 'react-icons/ri';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import isNumeric from '../../../../lib/isNumeric';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { HasRights, PrivateComponent } from '../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../const/permissions';
import { DATE_TIME_FORMAT, EMPTY_IMAGE_PLACEHOLDER } from '../../../../const/system';
import urlPageFeaturesEdit from '../../../../urls/urlPageFeaturesEdit';
import getFormattedDate from '../../../../lib/getFormattedDate';
import PublishedTag from '../../../../components/PublishedTag';
import StatusTag from '../../../../components/StatusTag';
import CollapsibleText from '../../../../components/CollapsibleText';



dayjs.extend(isSameOrBefore);


export const FEATURES_CONTENT_TYPE_MAP = {
  DAILY: 'daily',
  EDITOR_CHOICE: 'editors-choice',
  EXTERNAL_PROMO: 'external-promo',
};

export const getDictionaryIdFromDynamicData = (data) => {
  if (!isEmpty(data)) {
    const dictionary = data.filter((item) => item.type === 'dictionary')[0];

    return isNumeric(dictionary?.value) ? Number(dictionary?.value) : null;
  }
  return null;
};

export const getFeaturesTableColumns = (
  handlePreviewDictionary,
  handleViewDetails,
  handleDelete,
  handleCardClick,
  showImageAsPreview = false,
  featureConfig,
  featureSource,
  featureSourceKey,
  tableSize,
) => [
  {
    title: <IntlMessages id="ui-general-id" />,
    dataIndex: 'id',
    align: 'center',
    width: tableSize.width * 0.06,
    sorter: true,
  },
  {
    title: <IntlMessages id="ui-general-published" />,
    align: 'center',
    dataIndex: 'is_published',
    sorter: true,
    width: tableSize.width * 0.12,
    render: (value, item) => <PublishedTag published={Boolean(value)} publishedDate={item.published_at} />,
  },
  {
    title: <IntlMessages id="ui-general-preview" />,
    align: 'center',
    dataIndex: 'image_id',
    width: tableSize.width * 0.14,
    sorter: showImageAsPreview,
    render: (value, item) => {
      const isImage = !isEmpty(item.images);

      return (
        <div style={{ display: 'inline-grid' }}>
          <LazyLoadImage
            onClick={() => {
              if (!isEmpty(item?.preview)) {
                handleCardClick({ isImage, data: isImage ? item.images.data[0] : item.preview });
              }
            }}
            style={!isEmpty(item?.preview) ? { cursor: 'pointer' } : {}}
            height={90}
            className='hp-cursor-pointer'
            effect='blur'
            alt="features img preview"
            src={item?.preview ?? EMPTY_IMAGE_PLACEHOLDER}
          />
          {isImage && !isEmpty(item?.images?.data[0]) && (
            <Tooltip
              placement="right"
              title={() => {
                return (
                  <div>
                    <IntlMessages id="features-form-image-id-label" />
                    :&nbsp;
                    {item?.images?.data[0]?.id}
                  </div>
                );
              }}
            >
              <Tag
                className='hp-mt-4'
                style={{ margin: 'unset' }}
                color='default'
              >
                {item?.images?.data[0]?.id}
              </Tag>
            </Tooltip>
          )}
        </div>
      );
    },
  },
  {
    title: <IntlMessages id="ui-general-title" />,
    dataIndex: 'title',
    width: tableSize.width * 0.12,
    align: 'center',
    render: (item, value) => {
      const rows = item.split('\n');

      return (
        rows.map((content, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>
            <CollapsibleText text={content} />
            &nbsp;
            {index === 0 && value?.is_published && dayjs(value.updated_at).isAfter(dayjs(value.published_at)) && (
              <Tooltip placement="right" title={<IntlMessages id="ui-general-unpublished-tooltip-title" />}>
                &nbsp;
                <ExclamationCircleOutlined className="hp-text-color-warning-1" />
              </Tooltip>
            )}
          </div>
        ))
      );
    },
  },
  ...featureConfig?.config?.show_in_featured_enabled ? (
    [ {
      title: <IntlMessages id="features-table-featured-column" />,
      dataIndex: 'is_in_featured',
      align: 'center',
      width: tableSize.width * 0.1,
      render: (value) => <StatusTag active={Boolean(value)} />,
    } ]
  ) : [],
  {
    title: <IntlMessages id="ui-general-updated-at" />,
    dataIndex: 'updated_at',
    align: 'center',
    sorter: true,
    width: tableSize.width * 0.12,
    render: (value) => getFormattedDate(value, DATE_TIME_FORMAT),
  },
  {
    title: <IntlMessages id="ui-general-released-at" />,
    dataIndex: 'released_at',
    align: 'center',
    sorter: true,
    width: tableSize.width * 0.12,
    render: (value) => getFormattedDate(value, DATE_TIME_FORMAT),
  },
  ...featureConfig?.config?.release_range ? (
    [ {
      title: <IntlMessages id="ui-general-released-to" />,
      dataIndex: 'released_to',
      align: 'center',
      sorter: true,
      width: tableSize.width * 0.12,
      render: (value) => getFormattedDate(value, DATE_TIME_FORMAT),
    } ]
  ) : [],
  ...(HasRights([
    Permissions.FEATURED.FEATURES[featureSourceKey].FIND,
    Permissions.FEATURED.FEATURES[featureSourceKey].EDIT,
    Permissions.FEATURED.FEATURES[featureSourceKey].DELETE,
    Permissions.TRANSLATIONS.DICTIONARIES.DETAILS.VIEW,
  ]) ?
    [ {
      title: <IntlMessages id="ui-general-action" />,
      key: 'action',
      align: 'center',
      width: tableSize.width * 0.13,
      render: (item) => {
        const dictionaryId = getDictionaryIdFromDynamicData(item?.dynamic_data?.data);
        const canEditPastItem = featureConfig?.config?.publish?.allow_update_past ?? true;
        const canEditItem = canEditPastItem ? true : dayjs().isSameOrBefore(dayjs(item?.released_at).subtract(-1, 'days'), 'day');

        return (
          <Row className="da-h-100" align="middle" justify="center">
            <PrivateComponent allowedPermissions={[ Permissions.FEATURED.FEATURES[featureSourceKey].FIND ]}>
              <Tooltip placement="top" title={<IntlMessages id="ui-general-view" />}>
                <div className="hp-text-right">
                  <Eye
                    onClick={() => {
                      handleViewDetails(item.id);
                    }}
                    size={20}
                    className="hp-cursor-pointer hp-transition hp-hover-text-color-primary-1 hp-text-color-black-80 hp-m-4"
                  />
                </div>
              </Tooltip>
            </PrivateComponent>
            <PrivateComponent allowedPermissions={[ Permissions.FEATURED.FEATURES[featureSourceKey].EDIT ]}>
              {canEditItem && (
                <Tooltip placement="top" title={<IntlMessages id="ui-general-edit" />}>
                  <div className="hp-text-right">
                    <Link to={urlPageFeaturesEdit({ featureSource, featureId: item.id })}>
                      <PiPencil
                        size={20}
                        className="hp-cursor-pointer hp-transition hp-hover-text-color-warning-1 hp-text-color-black-80 hp-m-4"
                      />
                    </Link>
                  </div>
                </Tooltip>
              )}
            </PrivateComponent>
            <PrivateComponent allowedPermissions={[ Permissions.FEATURED.FEATURES[featureSourceKey].DELETE ]}>
              {!item.is_published ? (
                <Popconfirm
                  title={<IntlMessages id="ui-general-delete-confirm-message" />}
                  placement="top"
                  onConfirm={() => {
                    handleDelete(item.id);
                  }}
                  okText={<IntlMessages id="ui-general-yes" />}
                  cancelText={<IntlMessages id="ui-general-no" />}
                  icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
                  okButtonProps={{ danger: true }}
                >
                  <Tooltip placement="top" title={<IntlMessages id="ui-general-delete" />}>
                    <div className="hp-text-right">
                      <Delete
                        size={20}
                        className="hp-cursor-pointer hp-transition hp-hover-text-color-danger-1 hp-text-color-black-80 hp-m-4"
                      />
                    </div>
                  </Tooltip>
                </Popconfirm>
              ) : (
                <Tooltip placement="top" title={<IntlMessages id="features-delete-notification" />}>
                  <div className="hp-text-right">
                    <Delete
                      disabled
                      size={20}
                      className="hp-opacity-4 hp-cursor-pointer hp-transition hp-text-color-black-80 hp-m-4"
                    />
                  </div>
                </Tooltip>
              )}
            </PrivateComponent>
            <PrivateComponent allowedPermissions={[ Permissions.TRANSLATIONS.DICTIONARIES.DETAILS.VIEW ]}>
              {dictionaryId ? (
                <Tooltip placement="top" title={<IntlMessages id="ui-general-dictionary" />}>
                  <div className="hp-text-right">
                    <IoEarth
                      size={20}
                      onClick={() => {
                        handlePreviewDictionary(dictionaryId);
                      }}
                      className="hp-cursor-pointer hp-transition hp-hover-text-color-primary-1 hp-text-color-black-80 hp-m-4"
                    />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip placement="top" title={<IntlMessages id="dictionaries-empty-notification" />}>
                  <div className="hp-text-right">
                    <IoEarth
                      size={20}
                      disabled
                      className="hp-opacity-4 hp-cursor-pointer hp-transition hp-hover-text-color-primary-1 hp-text-color-black-80 hp-m-4"
                    />
                  </div>
                </Tooltip>
              )}
            </PrivateComponent>
          </Row>
        );
      },
    } ] : []
  ),
];
