import React from 'react';
import { Formik } from 'formik';
import { Col, DatePicker, Row } from 'antd';
import { Form, FormItem, Input, ResetButton, SubmitButton, Select } from 'formik-antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import Yup from '../../../../vendor/yup';
import { statusOptions, typeOptions } from './FormNewsFilter.const';
import useDatepickerHandler from '../../../../app/hooks/useDatepickerHandler';
import { DATE_FORMAT } from '../../../../const/system';



const validationSchema = Yup.object().shape({
  id: Yup.number().min(1),
  title: Yup.string(),
  status: Yup.string(),
  type: Yup.string(),
  published_from: Yup.date(),
  published_to: Yup.date(),
});

const FormNewsFilter = ({
  initialValues,
  onSubmit,
  onCancel,
  isSubmitting,
}) => {
  const intl = useIntl();
  const { onBlur } = useDatepickerHandler();

  return (
    <Formik
      enableReinitialize
      isSubmitting
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onReset={onCancel}
    >
      {({ isValid, dirty, values, setFieldValue }) => {
        return (
          <Form layout="vertical" >
            <FormItem
              label={intl.formatMessage({ id: 'ui-general-id' })}
              name='id'
            >
              <Input
                name="id"
                type="number"
                placeholder={intl.formatMessage({ id: 'news-form-id-placeholder' })}
              />
            </FormItem>

            <FormItem
              label={intl.formatMessage({ id: 'news-form-translations-label' })}
              name='title'
            >
              <Input
                name="title"
                placeholder={intl.formatMessage({ id: 'ui-general-title' })}
              />
            </FormItem>

            <Row gutter={16}>
              <Col span={12}>
                <FormItem
                  label={intl.formatMessage({ id: 'ui-general-status' })}
                  name='status'
                >
                  <Select
                    allowClear
                    name="status"
                    options={statusOptions}
                    placeholder={intl.formatMessage({ id: 'ui-general-choose-status' })}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label={intl.formatMessage({ id: 'ui-general-type' })}
                  name='type'
                >
                  <Select
                    allowClear
                    name="type"
                    options={typeOptions}
                    placeholder={intl.formatMessage({ id: 'ui-general-choose-type' })}
                  />
                </FormItem>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <FormItem
                  label={intl.formatMessage({ id: 'ui-general-published-from' })}
                  name='published_from'
                >
                  <DatePicker
                    name='published_from'
                    format={DATE_FORMAT}
                    showToday
                    value={values?.published_from ? moment(values.published_from) : null}
                    onChange={async (date, dateString) => {
                      await setFieldValue('published_from', dateString);
                    }}
                    onBlur={async ({ target }) => {
                      await onBlur('published_from', target.value, setFieldValue);
                    }}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label={intl.formatMessage({ id: 'ui-general-published-to' })}
                  name='published_to'
                >
                  <DatePicker
                    name='published_to'
                    format={DATE_FORMAT}
                    value={values?.published_to ? moment(values.published_to) : null}
                    onChange={async (date, dateString) => {
                      await setFieldValue('published_to', dateString);
                    }}
                    onBlur={async ({ target }) => {
                      await onBlur('published_to', target.value, setFieldValue);
                    }}
                  />
                </FormItem>
              </Col>
            </Row>

            <Row gutter={[ 16, 16 ]} justify='end'>
              <Col>
                <ResetButton disabled={false}>
                  <IntlMessages id='ui-general-reset' />
                </ResetButton>
              </Col>

              <Col>
                <SubmitButton
                  type="primary"
                  loading={isSubmitting}
                  disabled={!isValid || !dirty}
                >
                  <IntlMessages id="ui-general-apply" />
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

FormNewsFilter.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};


export default FormNewsFilter;
