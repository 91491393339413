import React from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { Card, Col, Row, Typography } from 'antd';
import urlPageDictionariesImport from '../../../../../urls/urlPageDictionariesImport';
import urlPageDictionaries from '../../../../../urls/urlPageDictionaries';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import urlPageTranslationDetails from '../../../../../urls/urlPageTranslationDetails';



const { Paragraph } = Typography;

const PageDictionariesImportSuccess = () => {
  const [ searchQueryParams ] = useSearchParams();
  const navigationSource = searchQueryParams.get('source') ?? null;
  const { state } = useLocation();
  const { dictionaries } = state;

  if (dictionaries.length) {
    return <div className="hp-text-color-danger-2"><IntlMessages id='dictionaries-import-page-failed' /></div>;
  }

  const importedDictionaries = () => {
    return dictionaries.data.map((dictionary) => {
      return (
        <div key={dictionary.id} className="hp-mb-10">
          <p className="hp-font-weight-500"><Link to={urlPageTranslationDetails({ id: dictionary.id, source: navigationSource })}>{dictionary.name}</Link></p>
          {dictionary?.structure?.data?.fields?.data?.map((field) => {
            const translation = dictionary?.translations?.data?.find((translation) => translation.structure_field_id === field.id);

            return (
              <p key={field.id} className="hp-ml-8">
                <span className="hp-font-weight-500">{field.label}</span>
                :
                &nbsp;
                {translation?.text}
              </p>
            );
          })}
        </div>
      );
    });
  };

  return (
    <>
      <Row gutter={32} className="hp-mb-32">
        <Col>
          <BreadCrumbs
            breadCrumbParent={
              <Link to={urlPageDictionaries({ source: navigationSource })}>
                <IntlMessages id='dictionaries-breadcrumbs' />
              </Link>
            }
            breadCrumbParent2={
              <Link to={urlPageDictionariesImport({ source: navigationSource })}>
                <IntlMessages id='ui-general-import' />
              </Link>
            }
            breadCrumbActive={<IntlMessages id='ui-general-success' />}
          />
        </Col>
      </Row>

      <Card title={<IntlMessages id='dictionaries-import-success' />}>
        <Paragraph className="hp-font-weight-500">
          <IntlMessages id="dictionaries-imported-list" />
          :
        </Paragraph>
        <Paragraph>
          <span className="hp-font-weight-500">
            <IntlMessages id='dictionaries-structure-label' />
            :
          </span>
          &nbsp;
          <span>{`${dictionaries.data[0]?.structure?.data?.source} v${dictionaries.data[0]?.structure?.data?.version}`}</span>
        </Paragraph>
        <br />
        {importedDictionaries()}
      </Card>
    </>
  );
};

export default PageDictionariesImportSuccess;
