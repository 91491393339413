import React from 'react';
import { Row, Tag, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { Eye } from 'iconsax-react';
import upperFirst from 'lodash/upperFirst';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import urlPageRBACUserDetails from '../../../../../urls/urlPageRBACUserDetails';
import urlPageRBACLogDetails from '../../../../../urls/urlPageRBACLogDetails';
import { DATE_TIME_FORMAT, EMPTY_VALUE_PLACEHOLDER } from '../../../../../const/system';
import { HasRights } from '../../../../../components/HasRights/HasRights';
import { hasRights, Permissions } from '../../../../../const/permissions';
import getFormattedDate from '../../../../../lib/getFormattedDate';
import CollapsibleText from '../../../../../components/CollapsibleText';



export const LOGS_ACTION_COLOR = (action) => {
  switch (action) {
    case 'create': return 'green';
    case 'update': return 'purple';
    case 'delete': return 'red';
    case 'attach': return 'cyan';
    case 'detach': return 'blue';
    case 'import': return 'lime';
    case 'export': return 'orange';
    case 'download': return 'geekblue';
    case 'clone': return 'default';
    case 'reopen': return 'gold';
    case 'unpublish': return 'volcano';
    case 'activate': return 'purple';
    case 'deactivate': return 'magenta';

    default: return 'default';
  }
};

export const getLogsListTableColumns = (tableSize) => [
  {
    title: <IntlMessages id="ui-general-id" />,
    dataIndex: 'id',
    align: 'center',
    width: tableSize.width * 0.07,
    sorter: true,
  },
  {
    title: <IntlMessages id="ui-general-user" />,
    align: 'center',
    width: tableSize.width * 0.16,
    render: (item) => {
      return (
        <div>
          {hasRights([ Permissions.ADMINISTRATION.USERS.USER_DETAILS.VIEW ]) && item?.user_id ? (
            <Link to={urlPageRBACUserDetails({ userId: item?.user_id })}>
              <CollapsibleText text={item?.user?.data?.name || EMPTY_VALUE_PLACEHOLDER} />
            </Link>
          ) : <CollapsibleText text={item?.user?.data?.name || EMPTY_VALUE_PLACEHOLDER} />}
        </div>
      );
    },
  },
  {
    title: <IntlMessages id="ui-general-content-type" />,
    dataIndex: 'content_type',
    align: 'center',
    width: tableSize.width * 0.20,
    render: (value) => upperFirst(value),
  },
  {
    title: <IntlMessages id="logs-table-event-col" />,
    dataIndex: 'event',
    align: 'center',
    width: tableSize.width * 0.11,
    render: (value) => <Tag color={LOGS_ACTION_COLOR(value)}>{upperFirst(value) || EMPTY_VALUE_PLACEHOLDER}</Tag>,
  },
  {
    title: <IntlMessages id="logs-table-message-col" />,
    dataIndex: 'message',
    align: 'center',
    width: tableSize.width * 0.25,
    render: (item) => <CollapsibleText text={item} />,
  },
  {
    title: <IntlMessages id="ui-general-created-at" />,
    dataIndex: 'created_at',
    align: 'center',
    sorter: true,
    render: (value) => getFormattedDate(value, DATE_TIME_FORMAT),
  },
  ...(HasRights([ Permissions.ADMINISTRATION.LOGS.DETAILS ]) ?
    [ {
      title: <IntlMessages id="ui-general-action" />,
      key: 'action',
      align: 'center',
      width: tableSize.width * 0.08,
      render: (item) => {
        return (
          <Row className="da-h-100" align="middle" justify="center">
            <Tooltip placement="top" title={<IntlMessages id="log-details-title" />}>
              <div className="hp-text-right">
                <Link to={urlPageRBACLogDetails({ logId: item.id })}>
                  <Eye
                    size={20}
                    className="hp-cursor-pointer hp-transition hp-hover-text-color-warning-1 hp-text-color-black-80 hp-m-4"
                  />
                </Link>
              </div>
            </Tooltip>
          </Row>
        );
      },
    } ] : []
  ),
];
