const resetFilter = ({
  searchQueryParams,
  setSearchParams,
  setInitFilterValues,
  navigationSource = null,
  callbacks = [],
}) => {
  const queryParams = {};

  Array.from(searchQueryParams.entries())
    .forEach(([ key, value ]) => {
      if (key === 'search') {
        if (navigationSource) {
          queryParams[key] = `source:${navigationSource}`;
        }
        return;
      }

      queryParams[key] = key === 'page' ? 1 : value;
    });

  setSearchParams(queryParams);
  setInitFilterValues({});

  callbacks.forEach((callback) => callback());
};

export default resetFilter;
