import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Form, FormItem, ResetButton, Select, SubmitButton } from 'formik-antd';
import { Col, Row } from 'antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import handleResponse from '../../../../lib/handleResponse';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { validationSchema } from './UpdateImageModalFormValidationSchema.const';
import Spinner from '../../../../components/Spinner';
import makeSelectFilterOption from '../../../../lib/makeSelectFilterOption';
import { useUpdateImageCategoriesMutation } from '../../api/imagesApiSlice';



const UpdateImageModalForm = ({ imageId, allCategories, imageCategories, onModalClose }) => {
  const intl = useIntl();
  const [ initialImageCategoriesValues, setInitialImageCategoriesValues ] = useState({});

  const [ updateImageCategories, { isLoading: isUpdatingImageCategories } ] = useUpdateImageCategoriesMutation();

  const categoryOptions = allCategories?.map((category) => {
    return {
      value: category.id,
      label: `${category.name} (${intl.formatMessage({ id: 'content-images-category-client-id' })}: ${category.client_id})`,
    };
  }) ?? [];

  const getInitialImageCategoriesValues = () => {
    const initialValues = {
      categories: [],
      main_category: null,
    };

    allCategories?.forEach((category) => {
      const currentImageCategory = imageCategories.find((imageCategory) => imageCategory.id === category.id);

      if (currentImageCategory) {
        if (currentImageCategory.is_main) {
          initialValues.main_category = currentImageCategory.id;
        }

        initialValues.categories.push(currentImageCategory.id);
      }
    });

    return initialValues;
  };

  useEffect(() => {
    setInitialImageCategoriesValues(getInitialImageCategoriesValues());
  }, [ imageId, imageCategories ]);

  const handleSubmit = (values) => {
    const payload = {
      categories: [],
    };

    if (values.categories && values.categories.length > 0) {
      values.categories.forEach((categoryId) => {
        payload.categories.push({
          category_id: categoryId,
          is_main: false,
        });
      });
    }

    if (values.main_category) {
      const mainCategory = payload.categories?.find((category) => category.category_id === values.main_category);

      if (mainCategory) {
        mainCategory.is_main = true;
      } else {
        payload.categories.push({
          category_id: values.main_category,
          is_main: true,
        });
      }
    }

    updateImageCategories({ id: imageId, payload })
      .unwrap()
      .then(() => onModalClose())
      .then(() => handleResponse('success', intl, 'ui-general-updated-successfully'))
      .catch((error) => handleResponse('error', intl, 'ui-general-update-failed', [], error));
  };

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={initialImageCategoriesValues}
      onSubmit={async (values, { resetForm }) => {
        await handleSubmit(values);
        await resetForm();
      }}
    >
      {({ isValid, dirty, handleReset }) => {
        return (
          <Form layout="vertical" className='update-image-modal-form'>
            <Spinner spinning={isUpdatingImageCategories}>
              <FormItem
                className='ant-form-item-col update-image-modal-form__form-item'
                name="main_category"
                label={intl.formatMessage({ id: 'content-images-main-category' })}
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                  size='small'
                  name="main_category"
                  options={categoryOptions}
                  placeholder={<IntlMessages id="content-images-main-category-placeholder" />}
                />
              </FormItem>

              <FormItem
                className='ant-form-item-col update-image-modal-form__form-item'
                name="categories"
                label={intl.formatMessage({ id: 'ui-general-categories' })}
              >
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                  size='small'
                  name="categories"
                  options={categoryOptions}
                  placeholder={<IntlMessages id="content-images-main-categories-placeholder" />}
                />
              </FormItem>

              <Row gutter={[ 16, 16 ]} justify='end'>
                <Col>
                  <ResetButton
                    loading={isUpdatingImageCategories}
                    onClick={() => {
                      handleReset();
                    }}
                  >
                    <IntlMessages id='ui-general-reset' />
                  </ResetButton>
                </Col>
                <Col>
                  <SubmitButton
                    loading={isUpdatingImageCategories}
                    disabled={!isValid || !dirty}
                  >
                    <IntlMessages id="ui-general-save" />
                  </SubmitButton>
                </Col>
              </Row>
            </Spinner>
          </Form>
        );
      }}
    </Formik>
  );
};

UpdateImageModalForm.propTypes = {
  imageId: PropTypes.number,
  allCategories: PropTypes.array,
  imageCategories: PropTypes.arrayOf(PropTypes.object),
  onModalClose: PropTypes.func,
};

export default UpdateImageModalForm;
