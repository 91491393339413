import React from 'react';
import PropTypes from 'prop-types';
import { Card, Descriptions, Tag, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import urlPageTranslationDetails from '../../../../urls/urlPageTranslationDetails';
import getFormattedDate from '../../../../lib/getFormattedDate';
import { DATE_TIME_FORMAT, EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';
import { hasRights, Permissions } from '../../../../const/permissions';



const CategoryDetailsCard = ({ data, dictionary, dictionaryId }) => {
  return (
    <Card className="hp-border-color-black-40 hp-card-6" title={data?.name}>
      <Descriptions
        layout="vertical"
        bordered
        className="hp-mb-32"
        column={{ xxl: 4, sm: 3, xs: 2 }}
      >
        <Descriptions.Item
          label={(
            <>
              <IntlMessages id="ui-general-id" />
              <Tooltip className="hp-ml-8" placement="topRight" title={<IntlMessages id="categories-create-form-field-client-id-help" />}>
                <InfoCircleOutlined />
              </Tooltip>
            </>
          )}
        >
          {data?.client_id}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-name" />}>
          {data?.name || EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-status" />}>
          <Tag color={data?.status ? 'success' : 'error'}>
            <IntlMessages id={data?.status ? 'ui-general-on' : 'ui-general-off'} />
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-dictionary" />}>
          {dictionaryId ? (
            <Link to={urlPageTranslationDetails({ id: dictionaryId })}>
              {dictionary || EMPTY_VALUE_PLACEHOLDER}
            </Link>
          ) : (
            <div>
              {dictionary || EMPTY_VALUE_PLACEHOLDER}
            </div>
          )}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-description" />}>
          {data?.description || EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-priority" />}>
          {data?.priority || EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-created-at" />}>
          {getFormattedDate(data?.created_at, DATE_TIME_FORMAT)}
        </Descriptions.Item>
        {hasRights([ Permissions.CONTENT.CATEGORIES.OPTIONS.EDIT ]) && (
          <Descriptions.Item
            label={(
              <>
                <IntlMessages id="categories-create-form-field-options" />
                <Tooltip className="hp-ml-8" placement="topRight" title={<IntlMessages id="categories-create-form-field-options-help" />}>
                  <InfoCircleOutlined />
                </Tooltip>
              </>
            )}
          >
            {data?.options || EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
        )}
      </Descriptions>
    </Card>
  );
};


CategoryDetailsCard.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    client_id: PropTypes.number,
    priority: PropTypes.number,
    status: PropTypes.bool,
    description: PropTypes.string,
    created_at: PropTypes.string,
    options: PropTypes.any,
  }).isRequired,
  dictionary: PropTypes.object,
  dictionaryId: PropTypes.number,
};

export default CategoryDetailsCard;
