import isEmpty from 'lodash/isEmpty';
import moment from 'moment/moment';
import Yup from '../../../../vendor/yup';



export const validationSchema  = Yup.lazy((values) => {
  return (
    Yup.object().shape({
      id: Yup.string()
        .min(1, 'Minimum 1 symbol required')
        .matches(/^\s*,?\s*\d+(?:\s*,\s*\d+)*\s*,?\s*$/, 'Only numbers and "," is allowed'),
      user_id: Yup.string().nullable(),
      content_type: Yup.string().nullable(),
      event: Yup.string().nullable(),
      created_from: Yup.string().test(
        'test_date_after',
        'Created from date must be same or before than created to',
        (value) => {
          if (!isEmpty(values?.created_to) && !isEmpty(value)) {
            return moment(values?.created_to).isSameOrAfter(value);
          }
          return true;
        },
      ),
      created_to: Yup.string().test(
        'test_date_before',
        'Created from date must be same or after than created at',
        (value) => {
          if (!isEmpty(values?.created_from) && !isEmpty(value)) {
            return moment(values?.created_from).isSameOrBefore(value);
          }
          return true;
        },
      ),
      message: Yup.string().max(191, 'Max 191 symbols required'),
    })
  );
});
