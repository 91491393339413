import React from 'react';
import PropTypes from 'prop-types';
import { Card, Descriptions, Tag } from 'antd';
import upperFirst from 'lodash/upperFirst';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import getFormattedDate from '../../../../lib/getFormattedDate';
import useCheckMobileScreen from '../../../../app/hooks/useCheckMobileScreen';
import { DATE_TIME_FORMAT, EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';
import { hasRights, Permissions } from '../../../../const/permissions';
import { LOGS_ACTION_COLOR } from '../../pages/Logs/PageLogs/PageLogs.const';



const LogDetailsCard = ({ data }) => {
  const isMobile = useCheckMobileScreen(600);

  return (
    <Card className="hp-border-color-black-40 hp-card-6">
      <Descriptions
        title={<IntlMessages id='log-details-title' />}
        layout={isMobile ? 'vertical' : 'horizontal'}
        bordered
        column={1}
      >
        <Descriptions.Item
          className='width-20-vw'
          label={<IntlMessages id="log-details-log-id" />}
        >
          {data.id}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-content-type" />}>
          {upperFirst(data?.content_type) || EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="log-details-content-id" />}>
          {data?.model_id || EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
        {hasRights([ Permissions.ADMINISTRATION.LOGS.VIEW_MODEL ]) && (
          <Descriptions.Item label={<IntlMessages id="log-details-model" />}>
            {data?.model || EMPTY_VALUE_PLACEHOLDER}
          </Descriptions.Item>
        )}
        <Descriptions.Item label={<IntlMessages id="logs-table-event-col" />}>
          <Tag color={LOGS_ACTION_COLOR(data?.event)}>
            {upperFirst(data?.event) || EMPTY_VALUE_PLACEHOLDER}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="logs-table-message-col" />}>
          {data?.message || EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="user-details-id-field" />}>
          {data?.user?.data?.id || EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="log-details-user-name" />}>
          {data?.user?.data?.name || EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-created-at" />}>
          {getFormattedDate(data?.created_at, DATE_TIME_FORMAT)}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};


LogDetailsCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    model_id: PropTypes.number,
    content_type: PropTypes.string,
    model: PropTypes.string,
    event: PropTypes.string,
    message: PropTypes.string,
    user: PropTypes.object,
    created_at: PropTypes.string,
  }).isRequired,
};

export default LogDetailsCard;
