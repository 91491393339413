import React from 'react';
import { Form, FormItem, Input, ResetButton, Select, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Col, DatePicker, Row, Space } from 'antd';
import { useIntl } from 'react-intl';
import moment from 'moment';
import Yup from '../../../../vendor/yup';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { DATE_FORMAT } from '../../../../const/system';



const validationSchema = Yup.object().shape({
  name: Yup.string(),
  source: Yup.string(),
  field: Yup.string(),
  field_value: Yup.string(),
  version: Yup.string(),
  created_from: Yup.date(),
  created_to: Yup.date(),
});

const FormFilterDictionary = ({
  onSubmit,
  onReset,
  filterOptions,
  initialValues,
  isSubmitting,
  formErrors = {},
  showSourceFilter = true,
}) => {
  const intl = useIntl();

  const filterSourceOptions = filterOptions.sources.map((source) => {
    return {
      label: intl.formatMessage({ id: `structures-source-${source.value}` }),
      value: source.value,
    };
  });

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialValues={initialValues}
      initialErrors={formErrors}
      onSubmit={onSubmit}
      onReset={onReset}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { isValid, setFieldValue, dirty, values = {} } = props;

        return (
          <Form layout="vertical" className='w-full'>
            <Row gutter={16}>
              <Col span={showSourceFilter ? 12 : 24}>
                <FormItem
                  className='ant-form-item-col'
                  name="name"
                  label={<IntlMessages id="ui-general-name" />}
                >
                  <Input
                    name='name'
                    placeholder={intl.formatMessage({ id: 'ui-general-name' })}
                  />
                </FormItem>
              </Col>
              {showSourceFilter && (
                <Col span={12}>
                  <FormItem
                    className='ant-form-item-col'
                    name="source"
                    label={<IntlMessages id="form-dictionaries-source-field" />}
                  >
                    <Select
                      name="source"
                      allowClear
                      options={filterSourceOptions}
                      placeholder={<IntlMessages id="form-dictionaries-source-placeholder" />}
                    />
                  </FormItem>
                </Col>
              )}
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <FormItem
                  className='ant-form-item-col'
                  name="field"
                  label={<IntlMessages id="form-dictionaries-field-field" />}
                >
                  <Select
                    defaultValue={initialValues.field}
                    allowClear
                    name="field"
                    options={filterOptions.fields}
                    placeholder={<IntlMessages id="form-dictionaries-field-placeholder" />}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  className='ant-form-item-col'
                  name="field_value"
                  label={<IntlMessages id="form-dictionaries-field-value-field" />}
                >
                  <Input
                    name='field_value'
                    placeholder={intl.formatMessage({ id: 'form-dictionaries-field-value-field' })}
                  />
                </FormItem>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <FormItem
                  className='ant-form-item-col'
                  name="version"
                  label={<IntlMessages id="form-dictionaries-structure-field" />}
                >
                  <Select
                    allowClear
                    name="version"
                    options={filterOptions.versions}
                    placeholder={<IntlMessages id="form-dictionaries-structure-placeholder" />}
                  />
                </FormItem>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <FormItem
                  className='ant-form-item-col'
                  name="created_from"
                  label={<IntlMessages id="ui-general-created-from" />}
                >
                  <DatePicker
                    name='created_from'
                    format={DATE_FORMAT}
                    value={values?.created_from ? moment(values.created_from) : null}
                    onChange={async (date, dateString) => {
                      await setFieldValue('created_from', dateString);
                    }}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  className='ant-form-item-col'
                  name="created_to"
                  label={<IntlMessages id="ui-general-created-to" />}
                >
                  <DatePicker
                    name='created_to'
                    value={values?.created_to ? moment(values.created_to) : null}
                    onChange={async (date, dateString) => {
                      await setFieldValue('created_to', dateString ? `${dateString}T23:59:59` : dateString);
                    }}
                  />
                </FormItem>
              </Col>
            </Row>

            <Row gutter={[ 16, 16 ]} justify='end'>
              <Col>
                <Space>
                  <ResetButton disabled={false} loading={isSubmitting}>
                    <IntlMessages id='ui-general-reset' />
                  </ResetButton>
                  <SubmitButton
                    loading={isSubmitting}
                    disabled={!isValid || !dirty}
                  >
                    <IntlMessages id='ui-general-apply' />
                  </SubmitButton>
                </Space>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

FormFilterDictionary.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    field: PropTypes.string,
    created_from: PropTypes.string,
    created_to: PropTypes.string,
  }).isRequired,
  filterOptions: PropTypes.shape({
    fields: PropTypes.array.isRequired,
    sources: PropTypes.array.isRequired,
    versions: PropTypes.array.isRequired,
  }).isRequired,
  formErrors: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
  showSourceFilter: PropTypes.bool,
};

export default FormFilterDictionary;

