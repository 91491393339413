import moment from 'moment/moment';
import { useIntl } from 'react-intl';
import { makeNotification, notificationTypes } from '../../lib/makeNotification';
import { dateFieldFormat } from '../../const/system';



const useDatepickerHandler = () => {
  const intl = useIntl();
  const onBlurDatepicker = async (field, value, setFieldValue) => {
    try {
      const parsedDate = moment(value).format(dateFieldFormat);

      if (parsedDate === 'Invalid date' && value) {
        throw new Error('Invalid date');
      }
      await setFieldValue(field, value);
    } catch {
      makeNotification(
        notificationTypes.error,
        intl.formatMessage({ id: 'ui-general-error' }),
        intl.formatMessage({ id: 'ui-general-invalid-date' }),
      );
      await setFieldValue(field, null);
    }
  };

  return { onBlur: onBlurDatepicker };
};

export default useDatepickerHandler;
