import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Card, Col, Input, Modal, Row, Space, Table, Typography } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { Search } from 'react-iconly';
import { RiAddLine, RiCloseFill } from 'react-icons/ri';
import { Filter } from 'iconsax-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import basePagination from '../../../../../const/pagination';
import urlPageDynamicNewsDetails from '../../../../../urls/urlPageDynamicNewsDetails';
import urlPageDynamicNewsCreate from '../../../../../urls/urlPageDynamicNewsCreate';
import urlPageDynamicNewsEdit from '../../../../../urls/urlPageDynamicNewsEdit';
import { getNewsTableColumns } from './PageDynamicNews.const';
import { HasRights } from '../../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../../const/permissions';
import useToggle from '../../../../../app/hooks/useToggle';
import useQueryParams from '../../../../../app/hooks/useQueryParams';
import getQueryParams from '../../../../../lib/getQueryParams';
import handleResponse from '../../../../../lib/handleResponse';
import makeTableLoadingObject from '../../../../../lib/makeTableLoadingObject';
import useElementSize from '../../../../../app/hooks/useElementSize';
import applyFilter from '../../../../../lib/applyFilter';
import resetFilter from '../../../../../lib/resetFilter';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import ActionButton from '../../../../../layout/components/action-button';
import Sidebar from '../../../../../components/Sidebar';
import FormDynamicNewsFilter from '../../../forms/FormDynamicNewsFilter';
import { useDeleteDynamicNewsMutation, useGetDynamicNewsQuery } from '../../../api/dynamicNewsApiSlice';



const { Title } = Typography;

const PageDynamicNews = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const [ elementRef, size ] = useElementSize();
  const [ initFilterValues, setInitFilterValues ] = useState({});
  const [ searchField, setSearchField ] = useState('');
  const [ previewImage, setPreviewImage ] = useState('');
  const [ isVisibleFilterSidebar, toggleFilterSidebar ]  = useToggle();
  const [ previewModalOpen, togglePreviewModal ] = useToggle();

  const searchFields = [ 'title' ];
  const searchFilterFields = [ 'id', 'title', 'is_published', 'template', 'template_key_value', 'released_at', 'released_to' ];

  const {
    pagination,
    search,
    setSearchTerm,
    searchParams,
    handleChangeTableParams,
  } = useQueryParams({ searchFields, searchFilterFields, isFilter: true });
  const { data: { data: news = [], pagination: newsPagination = {} } = { data: [], pagination: [] }, isFetching } = useGetDynamicNewsQuery({
    queryParams: `${searchParams.toString()}`,
  });
  const [ deleteDynamicNews, { isLoading: isDynamicNewsDeleting } ] = useDeleteDynamicNewsMutation();

  useEffect(() => {
    setSearchField(search);
  }, []);

  useEffect(() => {
    const queryParams = getQueryParams(window.location.search);

    if (queryParams.search) {
      const initValues = {};

      queryParams.search.split(';').forEach((item) => {
        const [ key, value ] = item.split(':');

        if (value !== '') {
          initValues[key] = value;
        }
      });

      setInitFilterValues(initValues);
    }
  }, []);

  useEffect(() => {
    if (!searchParams.toString()) {
      setInitFilterValues({
        reset: true, // ugly hack
      });
    }
  }, [ searchParams ]);

  const handleSearch = useCallback((event) => {
    setSearchField(event.target.value);
    setSearchTerm(event.target.value);
  }, []);

  const canViewTemplateColumn = HasRights([ Permissions.CONSTRUCTORS.TEMPLATES.FILTER ]);

  const handleView = (id) => {
    navigate(urlPageDynamicNewsDetails({ newsId: id }));
  };

  const handleEdit = (id) => {
    navigate(urlPageDynamicNewsEdit({ newsId: id }));
  };

  const handleDelete = (id) => {
    deleteDynamicNews(id)
      .unwrap()
      .then(() => handleResponse('success', intl, 'ui-general-deleted-successfully'))
      .catch((error) => handleResponse('error', intl, 'ui-general-delete-failed', [], error));
  };

  const handleCreate = () => {
    navigate(urlPageDynamicNewsCreate());
  };

  const handleImageCardClick = (preview) => {
    setPreviewImage(preview);
    togglePreviewModal();
  };

  const handlePreviewModalClose = () => {
    setPreviewImage('');
    togglePreviewModal();
  };

  return (
    <>
      <Modal
        width={500}
        title={intl.formatMessage({ id: 'content-image-preview-card-title' })}
        centered
        visible={previewModalOpen}
        onCancel={handlePreviewModalClose}
        footer={false}
        closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
      >
        <img alt="icon" src={previewImage} />
      </Modal>

      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs breadCrumbActive={<IntlMessages id='dynamic-news-breadcrumbs' />} />

        <Col md={16} span={24}>
          <Row
            gutter={[ 32, 32 ]}
            justify="end"
            align="middle"
          >
            <Col sm={12} span={24}>
              <Input
                allowClear={!isEmpty(search)}
                placeholder={intl.formatMessage({ id: 'ui-general-search-by-title' })}
                prefix={<Search set="curved" size={16} className="hp-text-color-black-80" />}
                value={searchField}
                onChange={handleSearch}
              />
            </Col>
            <Col>
              <Space>
                <ActionButton
                  title={<IntlMessages id='ui-general-create' />}
                  icon={<RiAddLine className="btn-icon-mr-1" />}
                  onClick={handleCreate}
                />
                <ActionButton
                  title=""
                  icon={<Filter size={18} />}
                  onClick={toggleFilterSidebar}
                />
              </Space>
            </Col>
          </Row>
        </Col>

        <Sidebar
          title={<Title level={5}>{intl.formatMessage({ id: 'dynamic-news-filter-title' })}</Title>}
          visible={isVisibleFilterSidebar}
          toggleSidebar={toggleFilterSidebar}
        >
          <FormDynamicNewsFilter
            initialValues={initFilterValues}
            isSubmitting={false}
            onCancel={() => {
              resetFilter({
                searchQueryParams,
                setSearchParams,
                setInitFilterValues,
                callbacks: [ () => setSearchField('') ],
              });
            }}
            onSubmit={(values) => {
              applyFilter({
                values,
                searchQueryParams,
                searchFilterFields,
                setSearchParams,
                setSearchField,
                toggleFilterSidebar,
              });
            }}
          />
        </Sidebar>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <div ref={elementRef}>
              <Table
                sticky
                scroll={{ x: 860 }}
                loading={makeTableLoadingObject(isFetching || isDynamicNewsDeleting)}
                rowKey="id"
                columns={getNewsTableColumns(handleView, handleEdit, handleDelete, handleImageCardClick, size, canViewTemplateColumn)}
                dataSource={news}
                onChange={handleChangeTableParams}
                pagination={{
                  ...basePagination,
                  current: pagination.page,
                  pageSize: pagination.limit,
                  total: newsPagination?.total,
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PageDynamicNews;
