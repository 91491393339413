const content = {
  'content-images-breadcrumbs': 'Images',
  'content-image-colors-amount': 'Colors amount',
  'content-image-taps': 'Taps amount',
  'content-images-folder-name-placeholder': 'Specify Bee Id(s)',
  'content-images-status-placeholder': 'Specify status(es)',
  'content-images-taps-from-label': 'Taps from',
  'content-images-taps-to-label': 'Taps to',
  'content-images-colors-from-label': 'Colors from',
  'content-images-colors-to-label': 'Colors to',
  'content-images-imported-from-label': 'Imported from',
  'content-images-imported-to-label': 'Imported to',
  'content-images-released-from-label': 'Released from',
  'content-images-category-label': 'Category',
  'content-images-category-placeholder': 'Specify category(es)',
  'content-images-category-client-id': 'Client ID',
  'content-images-category-deleted-at': 'Deleted at',
  'content-images-main-category-label': 'Only main category',
  'content-images-tags-label': 'Tags',
  'content-images-image-type-label': 'Image type',
  'content-images-image-type-placeholder': 'Specify image type(s)',
  'content-images-tag-placeholder': 'Specify tag(s)',
  'content-image-preview-card-title': 'Image preview',
  'content-image-preview-taps-label': 'Taps / Colors',
  'content-images-clone-confirm-message': 'Are you sure you want to clone image?',
  'content-images-reopen-confirm-message': 'Are you sure you want to reopen image?',
  'content-image-reopen-success': 'Image reopened successfully',
  'content-image-reopen-failed': 'Image reopen failed',
  'content-image-filter-loading': 'Filter loading...',
  'content-images-show-contour': 'Show lines',
  'content-images-show-colors': 'Show colors',
  'content-images-main-category': 'Main category',
  'content-images-main-category-placeholder': 'Choose main category',
  'content-images-main-categories-placeholder': 'Select categories',
};

export default content;
